export class User {
    public userId: any;
    public fullName: string;
    public mobile: string;
    public email: string;
    public isOtpVerified: boolean;
    public isBlocked: boolean;
    public createdAt: any;
    

    public constructor(data: User | {} = {}) {
        Object.assign(this, data);
    }
}

