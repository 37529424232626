import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuardGuard } from './guards/auth-guard.guard';
import { GeneralGuardGuard } from './guards/general-guard.guard';

export const routes: Routes = [
  {
    path: 'auth',
    // canActivate: [GeneralGuardGuard],
    loadChildren: () => import('./auth/auth.module')
      .then(m => m.NgxAuthModule),

  },
  {
    path: 'pages',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
      data: {
        role: 'JOB PROVIDER'
      }
  },
  {
    path: 'admin',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./admin/admin.module')
      .then(m => m.AdminModule),
      data: {
        role: 'ADMIN'
      }
  },
  {
    path: 'jobSeekers',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./job-seekers/job-seekers.module')
      .then(m => m.JobSeekersModule),
      data: {
        role: 'JOB SEEKER'
      }
  },
  {
    path: 'super-admin',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./super-admin/super-admin.module')
      .then(m => m.SuperAdminModule),
      data: {
        role: 'SUPER ADMIN'
      }
  },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
