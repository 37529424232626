import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `  `,
})
export class FooterComponent {
}

// <span class="created-by">
// Created by <b>INBEESIGHT</b> 2021
// </span>
